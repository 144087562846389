import _export from "../internals/export";
import _global3 from "../internals/global";
import _toAbsoluteIndex from "../internals/to-absolute-index";
import _toIntegerOrInfinity from "../internals/to-integer-or-infinity";
import _lengthOfArrayLike from "../internals/length-of-array-like";
import _toObject from "../internals/to-object";
import _arraySpeciesCreate from "../internals/array-species-create";
import _createProperty from "../internals/create-property";
import _arrayMethodHasSpeciesSupport from "../internals/array-method-has-species-support";
var $ = _export;
var _global2 = _global3;
var toAbsoluteIndex = _toAbsoluteIndex;
var toIntegerOrInfinity = _toIntegerOrInfinity;
var lengthOfArrayLike = _lengthOfArrayLike;
var toObject = _toObject;
var arraySpeciesCreate = _arraySpeciesCreate;
var createProperty = _createProperty;
var arrayMethodHasSpeciesSupport = _arrayMethodHasSpeciesSupport;
var HAS_SPECIES_SUPPORT = arrayMethodHasSpeciesSupport("splice");
var TypeError = _global2.TypeError;
var max = Math.max;
var min = Math.min;
var MAX_SAFE_INTEGER = 9007199254740991;
var MAXIMUM_ALLOWED_LENGTH_EXCEEDED = "Maximum allowed length exceeded"; // `Array.prototype.splice` method
// https://tc39.es/ecma262/#sec-array.prototype.splice
// with adding support of @@species

$({
  target: "Array",
  proto: true,
  forced: !HAS_SPECIES_SUPPORT
}, {
  splice: function splice(start, deleteCount
  /* , ...items */
  ) {
    var O = toObject(this);
    var len = lengthOfArrayLike(O);
    var actualStart = toAbsoluteIndex(start, len);
    var argumentsLength = arguments.length;
    var insertCount, actualDeleteCount, A, k, from, to;

    if (argumentsLength === 0) {
      insertCount = actualDeleteCount = 0;
    } else if (argumentsLength === 1) {
      insertCount = 0;
      actualDeleteCount = len - actualStart;
    } else {
      insertCount = argumentsLength - 2;
      actualDeleteCount = min(max(toIntegerOrInfinity(deleteCount), 0), len - actualStart);
    }

    if (len + insertCount - actualDeleteCount > MAX_SAFE_INTEGER) {
      throw TypeError(MAXIMUM_ALLOWED_LENGTH_EXCEEDED);
    }

    A = arraySpeciesCreate(O, actualDeleteCount);

    for (k = 0; k < actualDeleteCount; k++) {
      from = actualStart + k;
      if (from in O) createProperty(A, k, O[from]);
    }

    A.length = actualDeleteCount;

    if (insertCount < actualDeleteCount) {
      for (k = actualStart; k < len - actualDeleteCount; k++) {
        from = k + actualDeleteCount;
        to = k + insertCount;
        if (from in O) O[to] = O[from];else delete O[to];
      }

      for (k = len; k > len - actualDeleteCount + insertCount; k--) delete O[k - 1];
    } else if (insertCount > actualDeleteCount) {
      for (k = len - actualDeleteCount; k > actualStart; k--) {
        from = k + actualDeleteCount - 1;
        to = k + insertCount - 1;
        if (from in O) O[to] = O[from];else delete O[to];
      }
    }

    for (k = 0; k < insertCount; k++) {
      O[k + actualStart] = arguments[k + 2];
    }

    O.length = len - actualDeleteCount + insertCount;
    return A;
  }
});
export default {};